import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looking for a `}<Link to='/docs/v2/javascript-SDK/quick-start' mdxType="Link">{`quickstart`}</Link>{`?`}</p>
    <p>{`Let’s talk pricing. This guide will focus on the two most important Vexchange prices: the `}<strong parentName="p">{`mid price`}</strong>{` and the `}<strong parentName="p">{`execution price`}</strong>{`.`}</p>
    <h1 {...{
      "id": "mid-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#mid-price",
        "aria-label": "mid price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mid Price`}</h1>
    <p>{`The mid price, in the context of Vexchange, is the price that reflects the `}<em parentName="p">{`ratio of reserves in one or more pairs`}</em>{`. There are three ways we can think about this price. Perhaps most simply, it defines the relative value of one token in terms of the other. It also represents the price at which you could theoretically trade an infinitesimal amount (ε) of one token for the other. Finally, it can be interpreted as the current `}<em parentName="p">{`market-clearing or fair value price`}</em>{` of the assets.`}</p>
    <p>{`Let’s consider the mid price for VTHO-WVET (that is, the amount of VTHO per 1 WVET).`}</p>
    <h2 {...{
      "id": "direct",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#direct",
        "aria-label": "direct permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Direct`}</h2>
    <p>{`The simplest way to get the VTHO-WVET mid price is to observe the pair directly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, WVET, Fetcher, Route } from 'vexchange-sdk'

const VTHO = new Token(ChainId.MAINNET, '0x0000000000000000000000000000456E65726779', 18)

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const pair = await Fetcher.fetchPairData(VTHO, WVET[VTHO.chainId])

const route = new Route([pair], WVET[VTHO.chainId])

console.log(route.midPrice.toSignificant(6)) // 201.306
console.log(route.midPrice.invert().toSignificant(6)) // 0.00496756
`}</code></pre>
    <p>{`You may be wondering why we have to construct a `}<em parentName="p">{`route`}</em>{` to get the mid price, as opposed to simply getting it from the pair (which, after all, includes all the necessary data). The reason is simple: a route forces us to be opinionated about the `}<em parentName="p">{`direction`}</em>{` of trading. Routes consist of one or more pairs, and an input token (which fully defines a trading path). In this case, we passed WVET as the input token, meaning we’re interested in a WVET -> VTHO trade.`}</p>
    <p>{`Now we understand that the mid price is going to be defined in terms of VTHO/WVET. Not to worry though, if we need the WVET/VTHO price, we can easily invert.`}</p>
    <p>{`Finally, you may have noticed that we’re formatting the price to 6 significant digits. This is because internally, prices are stored as exact-precision fractions, which can be converted to other representations on demand. For a full list of options, see `}<Link to='/docs/v2/SDK/fractions#price' mdxType="Link">{`Price`}</Link>{`.`}</p>
    <h2 {...{
      "id": "indirect",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#indirect",
        "aria-label": "indirect permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Indirect`}</h2>
    <p>{`For the sake of example, let’s imagine a direct pair between VTHO and WVET `}<em parentName="p">{`doesn’t exist`}</em>{`. In order to get a VTHO-WVET mid price we’ll need to pick a valid route. Imagine both VTHO and WVET have pairs with a third token, USDC. In that case, we can calculate an indirect mid price through the USDC pairs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, WVET, Fetcher, Route } from 'vexchange-sdk'

const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6)
const VTHO = new Token(ChainId.MAINNET, '0x0000000000000000000000000000456E65726779', 18)

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const USDCWVETPair = await Fetcher.fetchPairData(USDC, WVET[ChainId.MAINNET])
const DAIUSDCPair = await Fetcher.fetchPairData(VTHO, USDC)

const route = new Route([USDCWVETPair, DAIUSDCPair], WVET[ChainId.MAINNET])

console.log(route.midPrice.toSignificant(6)) // 202.081
console.log(route.midPrice.invert().toSignificant(6)) // 0.00494851
`}</code></pre>
    <h1 {...{
      "id": "execution-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#execution-price",
        "aria-label": "execution price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Execution Price`}</h1>
    <p>{`Mid prices are great representations of the `}<em parentName="p">{`current`}</em>{` state of a route, but what about trades? It turns out that it makes sense to define another price, the `}<em parentName="p">{`execution`}</em>{` price of a trade, as the ratio of assets sent/received.`}</p>
    <p>{`Imagine we’re interested in trading 1 WVET for VTHO:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, WVET, Fetcher, Trade, Route, TokenAmount, TradeType } from 'vexchange-sdk'

const VTHO = new Token(ChainId.MAINNET, '0x0000000000000000000000000000456E65726779', 18)

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const pair = await Fetcher.fetchPairData(VTHO, WVET[VTHO.chainId])

const route = new Route([pair], WVET[VTHO.chainId])

const trade = new Trade(route, new TokenAmount(WVET[VTHO.chainId], '1000000000000000000'), TradeType.EXACT_INPUT)

console.log(trade.executionPrice.toSignificant(6))
console.log(trade.nextMidPrice.toSignificant(6))
`}</code></pre>
    <p>{`Notice that we’re constructing a trade of 1 WVET for as much VTHO as possible, `}<em parentName="p">{`given the current reserves of the direct pair`}</em>{`. The execution price represents the average VTHO/WVET price for this trade. Of course, the reserves of any pair can change every block, which would affect the execution price.`}</p>
    <p>{`Also notice that we’re able to access the `}<em parentName="p">{`next`}</em>{` mid price, if the trade were to complete successfully before the reserves changed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      